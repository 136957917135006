import { BreakpointObserver } from '@angular/cdk/layout';
import { OverlayModule } from '@angular/cdk/overlay';
import { PortalModule } from '@angular/cdk/portal';
import { registerLocaleData } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClient, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import localeDa from '@angular/common/locales/da';
import localeDe from '@angular/common/locales/de';
import localeFr from '@angular/common/locales/fr';
import localeLt from '@angular/common/locales/lt';
import localeLv from '@angular/common/locales/lv';
import localeNb from '@angular/common/locales/nb';
import localePl from '@angular/common/locales/pl';
import localeRu from '@angular/common/locales/ru';
import localeSr from '@angular/common/locales/sr';
import {
  CUSTOM_ELEMENTS_SCHEMA,
  ErrorHandler,
  NgModule,
  provideExperimentalZonelessChangeDetection,
  provideZoneChangeDetection
} from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatTooltipModule } from '@angular/material/tooltip';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { ServiceWorkerModule } from '@angular/service-worker';
import { PushModule } from '@rx-angular/template/push';

import { environment } from '../environments/environment';
import { AppComponent } from './app.component';
import { appRoutes } from './app.routes';
import { ActionsModalsModule } from '@core/actions-modals/actions-modals.module';
import { provideFeatureFlags } from '@core/feature-flag/feature-flag.provider';
import { GlobalErrorHandler } from '@core/globalError.handler';
import { TokenInterceptor } from '@core/token.interceptor';
import { createTranslateLoader } from '@core/translation/translation.service';
import { provideTranslations } from '@core/translation/translations.provider';
import { WebSocketService } from '@core/web-socket.service';
import { MobileSchedulerModule } from '@features/mobile-scheduler/mobile-scheduler.module';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { RX_RENDER_STRATEGIES_CONFIG, RxRenderStrategiesConfig } from '@rx-angular/cdk/render-strategies';
import { CookieService } from '@services/common/cookie.service';
import { PQLEventService } from '@services/common/pql-event-tracking.service';
import { SegmentService } from '@services/common/segment.service';
import { ConfirmEmailBannerModule } from '@shared/components/confirm-email-banner/confirm-email-banner.module';
import { TooltipModule } from '@shared/directives/tooltip/tooltip.module';
import { AppDatepickerModule } from '@ui-kit/app-datepicker/app-datepicker.module';
import { AppToastModule } from '@ui-kit/app-toast/app-toast.module';
import { BtnLoaderModule } from '@ui-kit/btn-loader/btn-loader.module';
import { ButtonsModule } from '@ui-kit/buttons/buttons.module';
import { LoaderModule } from '@ui-kit/loader/loader.module';
import { CommonPipesModule } from '@ui-kit/pipes/common-pipes.module';
import { apiUrl, apiUrlFactory, isMobile, isMobileFactory } from '@ui-kit/utility/isMobile';
import { CrudHttpService, CrudService } from 'collection';
import { SocketIoConfig, SocketIoModule } from 'ngx-socket-io';
import { BreadcrumbModule } from 'xng-breadcrumb';

const CUSTOM_RX_ANGULAR_CONFIG: RxRenderStrategiesConfig<string> = {
  primaryStrategy: 'native',
  patchZone: true
};

registerLocaleData(localeNb);
registerLocaleData(localeRu);
registerLocaleData(localePl);
registerLocaleData(localeLt);
registerLocaleData(localeFr);
registerLocaleData(localeSr);
registerLocaleData(localeDe);
registerLocaleData(localeLv);
registerLocaleData(localeDa);

const config: SocketIoConfig = {
  url: environment.WEB_SOCKET_HOST, // socket server url;
  options: {
    transports: ['websocket']
  }
};

@NgModule({
  declarations: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [AppComponent],
  imports: [
    SocketIoModule.forRoot(config),
    OverlayModule,
    BrowserModule,
    BrowserAnimationsModule,
    ActionsModalsModule,
    BreadcrumbModule,
    RouterModule.forRoot(appRoutes, {
      initialNavigation: 'enabledBlocking',
      paramsInheritanceStrategy: 'always'
    }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient]
      }
    }),
    FormsModule,
    MatDialogModule,
    MatButtonModule,
    ReactiveFormsModule,
    LoaderModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
    BtnLoaderModule,
    ButtonsModule,
    PortalModule,
    MatTooltipModule,
    CommonPipesModule,
    AppToastModule,
    AppDatepickerModule,
    // IntercomModule.forRoot({
    //     appId: environment.INTERCOM_APP_ID, // from your Intercom config
    //     updateOnRouterChange: true // will automatically run `update` on router event changes. Default: `false`
    // }),
    MobileSchedulerModule,
    ConfirmEmailBannerModule,
    TooltipModule,
    PushModule
  ],
  providers: [
    CrudHttpService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    },
    {
      provide: isMobile,
      useFactory: isMobileFactory,
      deps: [BreakpointObserver]
    },
    {
      provide: apiUrl,
      useFactory: apiUrlFactory
      // useValue: environment.apiUrl
    },
    WebSocketService,
    CookieService,
    PQLEventService,
    SegmentService,
    { provide: ErrorHandler, useClass: GlobalErrorHandler },
    {
      provide: RX_RENDER_STRATEGIES_CONFIG,
      useValue: CUSTOM_RX_ANGULAR_CONFIG
    },
    // provideCollection(),
    provideFeatureFlags(),
    provideTranslations(),
    provideHttpClient(withInterceptorsFromDi()),
    provideExperimentalZonelessChangeDetection()
  ]
})
export class AppModule {
  constructor(private crud: CrudService) {
    this.crud.apiUrl = environment.apiUrl;
  }
}
