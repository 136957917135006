<section class="new-version-alert is-internet-explorer" *ngIf="showIEAlert">
  <div class="message">
    <i class="icon icon-Warning2_ico warn-icon mr-2"></i>
    <span translate
      >As of October 2021 Svenn no longer supports Internet Explorer 10. Use another Browser like Google Chrome or Microsoft
      Edge</span
    >

    <button translate (click)="goToLearnMore('https://svenn.com/ie6/')" class="btn btn__solid uppercase learn-more-btn ml-4">
      Learn more
    </button>
  </div>

  <button class="btn-close btn-ie" (click)="closeIEAlert()">
    <i class="icon icon-close_ico"></i>
  </button>
</section>

<div class="main-wrapper">
  <ng-container *ngIf="($isEmailConfirmOpened | push) && ($isValidRouteForConfirm | push)">
    <confirm-email-banner></confirm-email-banner>
  </ng-container>

  <div class="offline-mode" translate>You are offline now. Please check you internet connection.</div>

  <div
    class="block w-full h-full"
    [ngClass]="{ 'confirm-email-exist': ($isEmailConfirmOpened | push) && ($isValidRouteForConfirm | push) }">
    <router-outlet></router-outlet>
  </div>

  <div *ngIf="showUpdateMode" class="update-version-message">
    <span class="icon icon-settings"></span>

    <span translate>New version is available.</span>

    <span class="action" (click)="updateCache()" translate>Update</span>
  </div>

  <app-toast-container></app-toast-container>
  <tooltip></tooltip>

  <div class="close-hubspot-chat" (click)="closeChat()"></div>
</div>
